.el-scrollbar[data-v-30132bbf] {
  height: 70%;
}
.box .el-scrollbar__wrap[data-v-30132bbf] {
  overflow: scroll;
}
.buttonBtn[data-v-30132bbf] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin-top: 12px;
}