.btn[data-v-fc09d4b0] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
[data-v-fc09d4b0] .deparmentLayout .avue-crud .el-table {
  height: calc(100vh - 348px) !important;
  max-height: calc(100vh - 348px) !important;
}
[data-v-fc09d4b0] .el-tree {
  height: calc(100vh - 311px) !important;
}